import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { SEO } from "../components/Seo"

const Pay = () => (
  <Layout>
    <main className="container mx-auto flex flex-col-reverse rounded-xl bg-transparent pb-10 shadow-md md:my-20 md:max-w-5xl md:flex-row md:bg-white md:pb-0 2xl:my-40">
      <div className="mx-auto w-full bg-[#625afa] py-5 text-center shadow-md md:basis-2/5 md:p-12 ">
        <StaticImage
          src="../assets/images/scan-to-pay.png"
          alt="MD Furkanul Islam"
          as="div"
          width={176}
          placeholder="tracedSVG"
        />
      </div>
      <div className="w-full pt-10 text-center md:basis-3/5 md:p-12 ">
        <h1 className="mt-5 text-center text-4xl font-bold text-black md:mt-5 ">
          Hurrah..! It's Money Day
        </h1>
        <p className="mt-2 text-center text-xl">
          Hi hope you're satisfied with my services. If you have still any
          question, Please reach out me. Thanks.
        </p>
        <a
          className="mt-5 inline-block rounded-full border-2 border-none bg-button py-2 px-10 text-lg text-white transition duration-1000 ease-out hover:bg-indigo-500 hover:ease-in"
          href="https://buy.stripe.com/6oE16tfv50yGc3C6oq"
          target="_blank"
        >
          Pay Now
        </a>
      </div>
    </main>
  </Layout>
)

export default Pay

export const Head = () => (
  <SEO
    title="Pay Now"
    description="Hurrah..! It's Money Day"
    pathname="pay"
    children={undefined}
  ></SEO>
)
